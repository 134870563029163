import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQ from "../../utils"
import questions from "../../accesibilityFaq.json"

function AccessibilityRemediation() {
  return (
    <Layout>
      <SEO
        title="Accessibility Remediation Company in Delhi NCR, India, USA & UK"
        description="Accessibility remediation is one of the core services offered by D2i. D2i is one of the leading company in India specializing in accessibility remediation."
      />
      <div className="text-justify">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h1>Accessibility Remediation</h1>
              </div>
              <p>
                Accessibility remediation is the process of making your digital
                content usable by everyone. We help you reach everyone by making
                your digital presence accessible. This avoids legal risks and
                expands your audience.
              </p>
              <p>
                <strong>
                  Our accessibility remediation services ensure a smooth and
                  cost-efficient path to compliance with Section 508, ADA, WCAG
                  2.0, WCAG 2.1, or WCAG 2.2.
                </strong>
              </p>
              <h2 className="cloudhead h3">Assets that can be remediated</h2>
              <p className="mb-2">
                Our experts will provide you the following accessibility
                remediation services:
              </p>
              <ul>
                <li>Applications (web, mobile, tablets, etc.)</li>
                <li>Image files and graphical documents</li>
                <li>Media files such as video and audio</li>
                <li>Websites</li>
              </ul>
            </div>
          </div>
          <div className="FAQ">{FAQ(questions)}</div>
        </div>
      </div>
    </Layout>
  )
}

export default AccessibilityRemediation
